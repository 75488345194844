.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: auto;
	width: 100%;
	padding: 16px 0;
	margin-bottom: 20px;

}

.navbarContainer {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.logoContainer {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.logo {
	height: 64px;
}

.logoTitle {
	font-size: 24px;
	font-weight: 400;
	color: #d9d9d9;
}

.link {
	font-size: 18px;
	padding: 12px 24px;
}

.link:hover {
	background-color: #e65107;
	border-radius: 5px;
	color: #d9d9d9;
}

.right {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.togleContainer {
	display: none;
}


@media screen and (max-width: 1276px) {
	.container {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: column;
		margin-bottom: 70px;
		padding: 16px 0;


	}

	.navbarContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-right: 8px;
	}

	.logoContainer {
		display: flex;
		align-items: center;
	}

	.logo {
		display: flex;
		align-items: center;
		height: 64px;
	}

	.logoTitle {
		display: none;
	}

	.togleContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		width: 38px;
		position: relative;



	}

	.line {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 2px;
		background-color: #d9d9d9;

	}

	.lineOpen {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 38px;
		height: 2px;
		background-color: #d9d9d9;
		transition: transform 1s ease;
		position: absolute;
		cursor: pointer;


	}

	.lineOpen:nth-child(1) {
		transform: rotate(45deg)
	}

	.lineOpen:nth-child(2) {
		display: none;
	}

	.lineOpen:nth-child(3) {
		transform: rotate(-45deg)
	}

	.linksContainer {
		display: none;
	}

	.linksContainerClosed {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		margin-top: 100px;
		gap: 20px;
		font-size: 20px;
		font-weight: 500;
		background-color: #06152b;
		width: 100%;
		position: relative;

	}

	.link {
		font-size: 18px;
		padding: 12px 24px;
	}

	.link:hover {
		background-color: #e65107;
		border-radius: 5px;
		color: #d9d9d9;
	}
}