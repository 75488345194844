.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;	
	align-items: center;
	gap: 30px;
	
}

.title {
	font-size: 60px;
	background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

}

.itemContainer{	 
	display: flex;
	flex-wrap: wrap;		
	gap: 30px;
	justify-content: center;
	margin-bottom: 30px;
	
}

.imgContainer{
	background-color: #fff;
	box-shadow: 1px;
	width: 350px;
	height: 450px;
	border-radius: 5px;	
	align-items: center;
	justify-content: center;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;


	
}
.img{		
	object-fit: fill;
}

.imageTitle{
	height: 100px;	
	display: flex;
	align-items: center;	
	justify-content: center;
	font-size: 24px;
	font-weight: 700;
	color: #e65107;

}

@media screen and (max-width:720px) {
	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;	
		gap: 20px;
	
		
	}
	
	.title {
		font-size: 50px;
		background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
	
	}
	
	.itemContainer{	 
		display: flex;
		flex-wrap: wrap;		
		gap: 20px;
		justify-content: center;
		margin-bottom: 20px;
	
		
	}
	
	.imgContainer{
		background-color: #fff;
		box-shadow: 1px;
		width: 350px;
		height: 450px;
		border-radius: 5px;	 
		align-items: center;
		justify-content: center;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	

		
	}
	.img{		
		object-fit: fill;
	}
	
	.imageTitle{	
		display: flex;
		align-items: center;	
		justify-content: center;
		font-size: 24px;
		font-weight: 700;
		color: #e65107;
 
	
	}
	
}