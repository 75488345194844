@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: #06152b;
  color: #d9d9d9;
}

a {
  text-decoration: none;
  color: inherit;

}

.container {
  max-width: 1400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 60px;
}

@media screen and (max-width: 768px) {

  .container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 12px 8px;
  }
}
 