.container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 50px;
	padding: 8px;

}

.leftContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.title {
	font-size: 60px;
	background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

}

.desc {
	font-size: 24px;
	font-weight: 400;
	color: #d9d9d9;
}

.rightContainer {
	flex: 1;
	position: relative;
	
}

.image {
	width: 100%;
	height: 100%;
	object-fit: contain;

	

}

@media screen and (max-width:720px) {
	.container {
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		gap: 50px;
		height: calc(100vh - 100px);
		padding: 8px;


	}

	.leftContainer {
		display: flex;
		height: max-content;
		gap: 40px;
	}

	.title {
		font-size: 50px;
		background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;

	}

	.desc {
		font-size: 20px;
		font-weight: 400;
		color: #d9d9d9;
	}

	.rightContainer {
		display: flex;
		position: relative;
		overflow: hidden;

	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}