.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;	
	align-items: center;
	gap: 30px;
	
}

.title {
	font-size: 60px;
	background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

}

.itemContainer{	 
	display: flex;
	flex-wrap: wrap;		
	gap: 30px;
	justify-content: center;
	margin-bottom: 30px;
	
}

.imgContainer{
	background-color: #fff;
	box-shadow: 1px;
	width: 350px;
	height: 450px;
	border-radius: 5px;	
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	position: relative;
	flex-direction: column;	
	gap: 18px;

	  
}
.img{		
	object-fit: fill;
}
.content{
	display: flex;
	flex-direction: column;	
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 5px;
	
}
.imageTitle{ 
	font-size: 24px;
	font-weight: 700;
	color: #e65107;
	margin: 0 4px;

}
.imagePlace{ 
	display: flex;
	align-items: center;	
	justify-content: center;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	color: #817c7c;
	margin: 0 4px;

}

@media screen and (max-width:720px) {
	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;	

		
	}
	
	.title {
		font-size: 50px;
		background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
	
	}
	
	.itemContainer{	 
		display: flex;
		flex-wrap: wrap;	
		justify-content: center;
		margin-bottom: 30px;
		gap: 20px;
		
	}
	
	.imgContainer{
		background-color: #fff;
		box-shadow: 1px;
		width: 350px;
		height: 450px;
		border-radius: 5px;	
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		position: relative;
		flex-direction: column;	
		gap: 18px; 
	}

	.img{		
		object-fit: fill; 
		
	}
	.content{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column; 
		text-align: center;
	}
	
	.imageTitle{ 		
		font-size: 24px;
		font-weight: 500;
		color: #e65107;
	
	}
	.imagePlace{ 
		display: flex;
		align-items: center;	
		justify-content: center;
		font-size: 18px;
		font-weight: 500;
		color: #817c7c;

	}
	
}