.container {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-style: 14px;
	color: #d9d9d9;
}

.left {
	display: flex;
	
}

.right {
	display: flex;
	align-items: center;
	gap: 10px;
}

.icon {
	opacity: 0.6;
	cursor: pointer;
}