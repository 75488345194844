.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;

}

.title {
	font-size: 60px;
	background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

}

.subtitle {
	font-size: 28px;
	font-weight: 500;
	padding: 24px 0;
	background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

}

.contactInfoContainer {
	display: flex;
	gap: 100px;
	justify-content: space-between;
	align-items: center;
}

.text {
	font-size: 24px;
	font-weight: 400;
	cursor: pointer;
}

.leftContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1;
	gap: 10px;

}

.itemContainer {
	display: flex;
	align-items: center;
	gap: 20px;
	height: 70px;
	margin-bottom: 24px;

}

.icon {

	background-color: #d9d9d9;			
	border-radius: 50%;
	font-size: 72px;
	padding: 12px;

}


.rightContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}


.imgContact {
	height: 250px;
	object-fit: contain;


}

.map {
	height: 600px;
	width: 100%;
	border: none;

}

@media screen and (max-width: 720px) {
	.container {
		display: flex;
		flex-direction: column;
		padding: 0 8px;

	}

	.title {
		font-size: 50px;
		background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;

	}

	.subtitle {
		font-size: 24px;
		font-weight: 500;
		background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		padding: 16px 0;

	}

	.contactInfoContainer {
		display: block;
		flex-direction: column-reverse;


	}

	.text {
		font-size: 24px;
		font-weight: 400;
		cursor: pointer;
	}

	.leftContainer {
		display: flex;

	}

	.rightContainer {
		display: flex;
		height: 200px;
		width: 100%;
	}


	.imgContact {
		height: 100%;
		width: 100%;
		object-fit: contain;

	}


	.itemContainer {
		display: flex;
		align-items: center;
		gap: 20px;
		height: 60px;
		margin-bottom: 24px;


	}

	.icon {
		background-color: #d9d9d9;		 
		border-radius: 50%;
		font-size: 72px;
		padding: 12px;

	}

	.mapContainer {
		display: flex;

	}

	.map {
		height: 250px;
		width: 100%;
		border: none;
		object-fit: contain;
	}

}