.container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 50px;


}

.leftContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.title {
	font-size: 60px;
	background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.desc {
	font-size: 24px;
	font-weight: 400;
	color: #d9d9d9;
}

.button {
	width:max-content;
	padding: 20px;
	border: none;
	border-radius: 5px;
	font-size: 24px;
	background-color: #e65107;
	color: #d9d9d9;
	cursor: pointer;


}

.button:hover {
	background-color: hsla(20, 94%, 46%, 0.835);

}

.rightContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

.img {
	width: 100%;
	height: 500px;
	size: 100vw;
	object-fit: cover;
	border-radius: 12px;

}

@media screen and (max-width: 720px) {
	.container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-around;
		gap: 20px;
		padding: 0 8px;


	}

	.leftContainer {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 10px 0;
	}

	.title {
		font-size: 50px;
		background: linear-gradient(to bottom, #e65107 , #d9d9d9 );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
	}

	.desc {
		font-size: 20px;
		font-weight: 400;
		color: #d9d9d9;
	}

	.button {
		width: max-content;
		padding: 20px;
		border: none;
		border-radius: 5px;
		font-size: 20px;
		background-color: #e65107;
		cursor: pointer;


	}

	.rightContainer {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin: 10px 0;

	}


}